import styled from "styled-components";
import { Color, Font, breakpoints} from "@/shared/Constants";
import { TwoxTwoWH , TwoxTwoTitle, TwoxTwoVolanta , backgroundPhoto} from "../mixins-modules"; 




 /* //SACAR  */

export const ContentTwoxTwoFoto = styled.div`
    ${TwoxTwoWH}
    .contentVideo{
      height:auto;
      max-height: 315px;
      aspect-ratio:16/9;
    }
    picture{
      &::before{
        ${backgroundPhoto}
        }
    }
    .mt {
      margin-top: 20px;
      ${props => props.mamsEnabled && "margin-top:35px"};
      h2.title{
      ${TwoxTwoTitle}
      margin-bottom: 20px;
      }
      .volanta{
        ${TwoxTwoVolanta}
        ${props => props.mamsEnabled && "display:none"};
      }
      .data-txt{
        order:3!important;
        margin: 10px 0 5px;
      }
    }
  
`;
